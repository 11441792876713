import React from "react";
import { Modal } from "react-bootstrap";
import Reward from "../../assets/images/reward.png";
const ModalReward = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        dialogClassName="reward-dialog"
        onHide={props.handleClose}
        centered
        className="reward-popup">
        <Modal.Body>
          <div className="p-2 m-3">
            {props.errorTurn === "" ? (
              <>
                <div className="d-flex justify-content-center reward-box">
                  <img src={props.prizeImage ?? Reward} className="sized-img" />
                </div>
                <div className="text-center padding-custom">
                  <p className="fs-4 clr-primary px-4 text-uppercase fw-bold genos-regular mb-0">{props.prizeText}</p>
                </div>
              </>
            ) : (
              <p className="fs-5 text-danger fw-bold mb-0">{props.errorTurn}</p>
            )}
          </div>
        </Modal.Body>

        <div className="close-btn" onClick={props.handleClose} />
      </Modal>
    </>
  );
};

export default ModalReward;
